import gql from 'graphql-tag'

export const Importations = gql`
  query importations($id: ID!, $importationType: String) {
    importations(id: $id, importationType: $importationType) {
      id
      filename
      lines {
        id
        line
        message
        filename
      }
    }
  }
`
