import gql from 'graphql-tag'

export const BillingImport = gql`
  mutation billingImport($input: BillingImportInput!) {
    billingImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const IdocImport = gql`
  mutation idocImport($input: IdocImportInput!) {
    idocImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const EsarImport = gql`
  mutation esarImport($input: EsarImportInput!) {
    esarImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const PaymentConditionImport = gql`
  mutation paymentConditionImport($input: PaymentConditionImportInput!) {
    paymentConditionImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const PoImport = gql`
  mutation poImport($input: PoImportInput!) {
    poImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const HuaweiPoImport = gql`
  mutation huaweiPoImport($input: HuaweiPoImportInput!) {
    huaweiPoImport(input: $input) {
      clientMutationId
      success
    }
  }
`

export const HuaweiLpuImporter = gql`
  mutation huaweiLpuImporter($input: HuaweiLpuImportInput!) {
    huaweiLpuImporter(input: $input) {
      clientMutationId
      success
    }
  }
`

export const NokiaLpuImporter = gql`
  mutation nokiaLpuImporter($input: NokiaLpuImportInput!) {
    nokiaLpuImporter(input: $input) {
      clientMutationId
      success
    }
  }
`

