<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    width="1200"
    scrollable
    >
    <template v-slot:activator="{ on, attrs }">
      <v-card
        >
        <v-card-title
          class="justify-center"
          >
          Carga masiva POs
        </v-card-title>
        <v-card-text
          >
          <div
            style="height: 66px"
            >
          </div>

          <div
            class="text-right mt-3"
            >
            <v-btn
              depressed
              color="transparent"
              v-on="on"
              v-bind="attrs"
              >
              <img
                src="@/assets/icons/tiantar-upload-excel.png"
                height="36px"
                width="36px"
                ></img>
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </template>

    <v-card>
      <v-card-title>
        Carga masiva POs
      </v-card-title>

      <v-card-text>
        <div
          class="text-subtitle-1 text-center mb-3"
          >
          Nueva importación
        </div>

        <div>
          <v-file-input
            class="d-none"
            outlined
            solo
            flat
            dense
            @change="onChange"
            accept=".csv"
            ref="file"
            multiple
            label="Archivo"
            hide-details
            ></v-file-input>

          <v-card
            @dragover="dragover" 
            @dragleave="dragleave" 
            @drop="drop"
            outlined
            height="300"
            @click="clickInput"
            >
            <v-card-text
              class="d-flex justify-center fill-height"
              style="overflow: auto"
              >
              <div
                v-if="!files.length"
                class="fill-height d-flex justify-center align-center primary--text text-center"
                >
                Arrastre sus archivos CSV aquí o haga click para examinar.
              </div>

              <div
                v-else
                class="grow"
                :key="files.length"
                >
                <template
                  v-for="(file, i) in files"
                  >
                  <v-card
                    class="fill-width mb-2"
                    outlined
                    :key="i"
                    :color="error.includes(i) ? 'error lighten-2' : (successful.includes(i) ? 'success lighten-2' : '')"
                    >
                    <v-card-text
                      class="py-2 d-flex justify-space-between align-center"
                      >
                      <div
                        style="max-width: 220px !important"
                        class="grow"
                        >
                        {{ file.name }}
                      </div>

                      <div
                        class="mr-n1 shrink"
                        >
                        <v-btn
                          icon
                          small
                          color="red"
                          @click.stop="remove(i)"
                          >
                          <v-icon small>mdi-close</v-icon>
                        </v-btn>
                      </div>
                    </v-card-text>
                  </v-card>
                </template>
              </div>
            </v-card-text>
          </v-card>

          <v-btn
            class="rounded-lg py-5 mt-3"
            depressed
            color="primary"
            @click="perform"
            :disabled="!files.length"
            >
            Cargar
          </v-btn>
        </div>

        <v-divider
          class="my-3"
          ></v-divider>

        <div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ID
                  </th>
                  <th class="text-left">
                    Archivo
                  </th>
                  <th class="text-left">
                    Estado
                  </th>
                </tr>
              </thead>
              <tbody
                :key="band"
                >
                <template
                  v-for="(importation, i) in importations"
                  >
                  <tr
                    style="cursor: pointer"
                    :class="importation.lines.length ? 'red lighten-4' : 'success lighten-4'"
                    @click="showDetail(i)"
                    >
                    <td
                      style="width: 70px"
                      >
                      {{ importation.id }}
                    </td>
                    <td
                      style="width: 300px"
                      >{{ importation.filename }}</td>
                    <td>{{ importation.lines.length ? `Con errores (${importation.lines.length})` : 'Exitosa' }}</td>
                  </tr>

                  <template
                    v-if="show[i]"
                    >
                    <template
                      v-if="importation.lines.length"
                      >
                      <tr
                        v-for="line in importation.lines"
                        >
                        <td></td>
                        <td>Archivo: {{ line.filename }}</td>
                        <td>{{ line.message }}</td>
                      </tr>
                    </template>
                  </template>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { PoImport } from '@/graphql/mutations/importations'
import { Importations } from '@/graphql/queries/importations'

export default {
  data: () => ({
    importations: [],
    dialog: false,
    files: [],
    error: [],
    successful: [],
    band: 0,
    show: []
  }),

  created () {
    this.fetchImportations ()
  },

  methods: {
    perform () {
      this.$apollo.mutate({
        mutation: PoImport,
        variables: {
          input: {
            id: this.$route.params.id,
            files: this.files
          }
        }
      }).then ( res => {
        this.files = []
        this.fetchImportations()
      })
    },

    fetchImportations () {
      this.$apollo.query({
        query: Importations,
        variables: {
          id: this.$route.params.id,
          importationType: 'po'
        },
        fetchPolicy: 'no-cache'
      }).then ( res => {
        this.importations = res.data.importations
        this.importations.forEach ( (importation) => {
          this.show.push(false)
        })
      })
    },

    showDetail (i) {
      this.show[i] = !this.show[i]
      this.band += 1
    },

    onChange() {
      this.files = [
        ...this.files,
        ...this.$refs.file.$el.getElementsByTagName('input')[0].files
      ];

      this.$nextTick( () => {
        this.$refs.file.$el.getElementsByTagName('input')[0].value = null
        this.$forceUpdate()
      })
    },

    remove(i) {
      this.files.splice(i, 1);
    },

    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains('success')) {
        event.currentTarget.classList.add('success');
      }
    },

    dragleave(event) {
      // Clean up
      event.currentTarget.classList.remove('success');
    },

    drop(event) {
      event.preventDefault();
      this.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.remove('success');
    },

    clickInput () {
      this.$refs.file.$el.getElementsByTagName('input')[0].click()
    },
  }
}
</script>
